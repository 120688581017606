import React from "react";

const StepFiveIcon = ({ fill }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_590_31)">
        <path
          d="M22.0001 43.0851C33.6441 43.0851 43.0834 33.6458 43.0834 22.0018C43.0834 10.3578 33.6441 0.918457 22.0001 0.918457C10.3561 0.918457 0.916748 10.3578 0.916748 22.0018C0.916748 33.6458 10.3561 43.0851 22.0001 43.0851Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.2755 9.91113L31.9275 17.2591"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.7429 12.0726L34.1129 4.70264"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.90918 39.2772L17.2572 31.9292"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.0726 26.7446L4.72461 34.0926"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.1037 39.268L26.7576 31.9219"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.9348 26.73L39.2846 34.0798"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.73364 9.89795L12.078 17.2441"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.272 12.0652L9.92212 4.71533"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22 33.002C28.0751 33.002 33 28.0771 33 22.002C33 15.9268 28.0751 11.002 22 11.002C15.9249 11.002 11 15.9268 11 22.002C11 28.0771 15.9249 33.002 22 33.002Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_590_31">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StepFiveIcon;
