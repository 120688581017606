import React, { useState, useEffect, useRef } from "react";
import RouterBody from "./RouterBody";
import "./App.css";
import {
  Engine,
  Render,
  World,
  Bodies,
  Mouse,
  MouseConstraint,
  Composite,
  Runner,
  Body,
  Events,
  Common,
  Vector,
} from "matter-js";

// Expanded color palette with similar and complementary colors
const colorPalette = [
  // Primary Blue Variants
  "#2B5CFF", // Primary blue
  "#4A7FFF", // Lighter primary blue
  "#1A3CB3", // Darker primary blue
  "#5D7BFF", // Soft blue
  "#162B5C", // Deep blue

  // Secondary Purple Variants
  "#8D5FEE", // Secondary purple
  "#B38DFF", // Lighter secondary purple
  "#6A3FAD", // Darker secondary purple
  "#A382FF", // Soft lavender
  "#4A2B7C", // Deep purple

  // Complementary and Analogous Colors
  "#5F6EEE", // Blue-purple
  "#EE5FBB", // Magenta
  "#5FEEE0", // Turquoise
  "#EE935F", // Coral
  "#7F5FEE", // Indigo
];

// List of assets to preload
const assets = [
  process.env.PUBLIC_URL + "/logoCircled.png",
  process.env.PUBLIC_URL + "/heroImg1.png",
  process.env.PUBLIC_URL + "/heroImg2.png",
  process.env.PUBLIC_URL + "/heroImg3.png",
  process.env.PUBLIC_URL + "/ayoImage.png",
];

const LoadingScreen = ({ progress }) => {
  const sceneRef = useRef(null);
  const engineRef = useRef(null);
  const renderRef = useRef(null);
  const runnerRef = useRef(null);

  useEffect(() => {
    // Create engine
    const engine = Engine.create();
    engineRef.current = engine;

    // Create runner
    const runner = Runner.create();
    runnerRef.current = runner;

    // Create renderer
    const render = Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width: window.innerWidth,
        height: window.innerHeight,
        wireframes: false,
        background: "black",
      },
    });
    renderRef.current = render;

    // Body options
    const bodyOptions = {
      frictionAir: 0.01,
      friction: 0.0001,
      restitution: 0.8,
    };

    // Create a stack of shapes
    const shapesCount = 100;
    const shapes = [];

    for (let i = 0; i < shapesCount; i++) {
      // Randomize shape type
      const shapeType = Math.floor(Common.random(0, 3));

      let shape;
      const x = Common.random(0, window.innerWidth);
      const y = Common.random(0, window.innerHeight);
      const size = Common.random(10, 40);

      // Select a random color from the palette
      const fillStyle =
        colorPalette[Math.floor(Common.random(0, colorPalette.length))];

      switch (shapeType) {
        case 0: // Circle
          shape = Bodies.circle(x, y, size / 2, {
            ...bodyOptions,
            render: {
              fillStyle: fillStyle,
              strokeStyle: "#FFFFFF",
              lineWidth: 0,
            },
          });
          break;
        case 1: // Rectangle
          shape = Bodies.rectangle(x, y, size, size, {
            ...bodyOptions,
            render: {
              fillStyle: fillStyle,
              strokeStyle: "#FFFFFF",
              lineWidth: 0,
            },
          });
          break;
        case 2: // Polygon
          const sides = Math.round(Common.random(3, 6));
          shape = Bodies.polygon(x, y, sides, size / 2, {
            ...bodyOptions,
            render: {
              fillStyle: fillStyle,
              strokeStyle: "#FFFFFF",
              lineWidth: 0,
            },
          });
          break;
      }

      shapes.push(shape);
    }

    // Add shapes to the world
    Composite.add(engine.world, shapes);

    // Soft boundary force
    Events.on(engine, "afterUpdate", () => {
      shapes.forEach((body) => {
        const { x, y } = body.position;
        const padding = 50; // Soft boundary zone
        const forceMagnitude = 0.001;

        // Left boundary
        if (x < padding) {
          Body.applyForce(
            body,
            body.position,
            Vector.create(forceMagnitude, 0)
          );
        }
        // Right boundary
        if (x > window.innerWidth - padding) {
          Body.applyForce(
            body,
            body.position,
            Vector.create(-forceMagnitude, 0)
          );
        }
        // Top boundary
        if (y < padding) {
          Body.applyForce(
            body,
            body.position,
            Vector.create(0, forceMagnitude)
          );
        }
        // Bottom boundary
        if (y > window.innerHeight - padding) {
          Body.applyForce(
            body,
            body.position,
            Vector.create(0, -forceMagnitude)
          );
        }
      });
    });

    // Explosion effect
    const explosion = (delta) => {
      const timeScale = 1000 / 60 / delta;
      const bodies = Composite.allBodies(engine.world);

      for (let i = 0; i < bodies.length; i++) {
        const body = bodies[i];

        if (!body.isStatic) {
          const forceMagnitude = 0.05 * body.mass * timeScale;

          Body.applyForce(body, body.position, {
            x:
              (forceMagnitude + Common.random() * forceMagnitude) *
              (Math.random() > 0.5 ? 1 : -1),
            y: -forceMagnitude + Common.random() * -forceMagnitude,
          });
        }
      }
    };

    let timeScaleTarget = 1;
    let lastTime = Common.now();

    Events.on(engine, "afterUpdate", (event) => {
      const timeScale = (event.delta || 1000 / 60) / 1000;

      // Tween the timescale for slow-mo effect
      engine.timing.timeScale +=
        (timeScaleTarget - engine.timing.timeScale) * 12 * timeScale;

      // Every 2 seconds
      if (Common.now() - lastTime >= 2000) {
        // Flip the timescale
        timeScaleTarget = timeScaleTarget < 1 ? 1 : 0;

        // Create random forces
        explosion(event.delta);

        // Update last time
        lastTime = Common.now();
      }
    });

    // Add mouse control
    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

    Composite.add(engine.world, mouseConstraint);

    // Run engine and renderer
    Engine.run(engine);
    Render.run(render);
    Runner.run(runner, engine);

    // Cleanup
    return () => {
      Render.stop(render);
      Runner.stop(runner);
      World.clear(engine.world);
      Engine.clear(engine);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    };
  }, []);

  return (
    <div className="loading-container">
      <div ref={sceneRef} className="loading-scene" />
      <div className="loading-progress">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{
              width: `${progress}%`,
              backgroundColor: "#2B5CFF",
            }}
          />
        </div>
        <div className="progress-text">Loading... {Math.round(progress)}%</div>
      </div>
    </div>
  );
};

const Services = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   let loadedCount = 0;
  //   const totalAssets = assets.length;

  //   const promises = assets.map((src) => {
  //     return new Promise((resolve, reject) => {
  //       const img = new Image();
  //       img.src = src;
  //       img.onload = () => {
  //         loadedCount++;
  //         setProgress((loadedCount / totalAssets) * 100);
  //         resolve();
  //       };
  //       img.onerror = reject;
  //     });
  //   });

  //   Promise.all(promises)
  //     .then(() => {
  //       // Add a small delay for smooth transition
  //       setTimeout(() => setIsLoaded(true), 4000);
  //     })
  //     .catch((error) => console.error("Error loading assets:", error));
  // }, []);

  useEffect(() => {
    let loadedCount = 0;
    const totalAssets = assets.length;

    const promises = assets.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loadedCount++;
          // Only fill up to 80% of the progress bar during asset loading
          const assetProgress = (loadedCount / totalAssets) * 80;
          setProgress(assetProgress);
          resolve();
        };
        img.onerror = reject;
      });
    });

    Promise.all(promises)
      .then(() => {
        // Assets are loaded (progress at 80%)
        // Add a 400ms delay, then show 100%
        setTimeout(() => {
          setProgress(100); // Set to 100%

          // Add another small delay to show 100% before transitioning
          setTimeout(() => {
            setIsLoaded(true); // Show the main content
          }, 500); // Display 100% for 500ms before transitioning
        }, 4000);
      })
      .catch((error) => console.error("Error loading assets:", error));
  }, []);

  if (!isLoaded) {
    return <LoadingScreen progress={progress} />;
  }

  return <RouterBody />;
};

export default Services;
