import { motion } from "framer-motion";
import { useState } from "react";
import "./index.css";

const Button = ({
  activeColor = "#2B5CFF",
  hoverColor = "red",
  activeTextColor = "#fff",
  activeHoverTextColor = "#fff",
  width = "150px",
  height = "50px",
  borderRadius = "8px",
  children,
  onClick,
}) => {
  return (
    <button
      onClick={onClick ?? null}
      className="app-button"
      style={{ backgroundColor: activeColor, color: activeTextColor }}
    >
      {children}
    </button>
  );
};

export default Button;
