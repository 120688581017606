// import fwork1 from "../../../assets/images/Fwork1.png";
// import fwork2 from "../../../assets/images/Fwork2.png";
// import fwork3 from "../../../assets/images/Fwork3.png";
// import fwork4 from "../../../assets/images/work4.png";
// import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import Button from "../../../components/button";
// import "./FeaturedWork.css";
// import { useLocation } from "react-router-dom";

// gsap.registerPlugin(ScrollTrigger);

// const FeaturedWork = () => {
//   const containerRef = useRef(null);
//   const trackRef = useRef(null);
//   const scrollTriggerRef = useRef(null);
//   const location = useLocation();
//   const [imagesLoaded, setImagesLoaded] = useState(false);

//   const featuredProjects = [
//     {
//       id: 1,
//       title: "Breezeflow",
//       image: fwork1,
//       tags: ["Software Development", "UX/UI Design"],
//       year: "2024",
//     },
//     {
//       id: 2,
//       title: "Scooly",
//       image: fwork2,
//       tags: ["Software Development", "UX/UI Design"],
//       year: "2024",
//     },
//     {
//       id: 3,
//       title: "CozyIntro",
//       image: fwork3,
//       tags: ["Software Development", "UX/UI Design"],
//       year: "2024",
//     },
//     {
//       id: 4,
//       title: "Retinalogik",
//       image: fwork4,
//       tags: ["Software Development", "UX/UI Design"],
//       year: "2024",
//     },
//     {
//       id: 5,
//       title: "Scooly",
//       image: fwork2,
//       tags: ["Software Development", "UX/UI Design"],
//       year: "2024",
//     },
//   ];

//   // Check when all images are loaded
//   useEffect(() => {
//     const imagePromises = featuredProjects.map((project) => {
//       return new Promise((resolve) => {
//         const img = new Image();
//         img.src = project.image;
//         img.onload = () => resolve();
//         img.onerror = () => resolve(); // Handle errors gracefully
//       });
//     });

//     Promise.all(imagePromises).then(() => {
//       setImagesLoaded(true);
//     });
//   }, []);

//   // Initialize ScrollTrigger after images are loaded
//   useLayoutEffect(() => {
//     if (!imagesLoaded) return;

//     const container = containerRef.current;
//     const track = trackRef.current;

//     if (!container || !track) return;

//     // Add loaded class to track for fade-in animation
//     track.classList.add("loaded");

//     const sections = gsap.utils.toArray(".project-card");

//     // Calculate the total width needed for all cards plus right padding for last card
//     const cardWidth = window.innerWidth <= 768 ? 300 : 499;
//     const cardGap = 24;
//     const rightPadding = window.innerWidth <= 768 ? 20 : 40;
//     const totalWidth =
//       sections.length * (cardWidth + cardGap) - cardGap + rightPadding;
//     const viewportWidth = window.innerWidth;
//     const scrollDistance = Math.max(0, totalWidth - viewportWidth);

//     // Set container height to match card height
//     container.style.height = window.innerWidth <= 768 ? "400px" : "636px";

//     // Clear any existing ScrollTrigger instance
//     if (scrollTriggerRef.current) {
//       scrollTriggerRef.current.kill();
//       scrollTriggerRef.current = null;
//     }

//     // Only create ScrollTrigger if there's actual scrolling to do
//     if (scrollDistance > 0) {
//       try {
//         scrollTriggerRef.current = ScrollTrigger.create({
//           trigger: ".featured-work",
//           start: "top top",
//           pin: container,
//           pinSpacing: true,
//           scrub: 1,
//           end: `+=${scrollDistance}`,
//           invalidateOnRefresh: true,
//           animation: gsap.to(track, {
//             x: -scrollDistance,
//             ease: "none",
//           }),
//           onRefresh: () => {
//             // Recalculate dimensions on refresh if ScrollTrigger exists
//             if (scrollTriggerRef.current) {
//               const updatedCardWidth = window.innerWidth <= 768 ? 300 : 499;
//               const updatedTotalWidth =
//                 sections.length * (updatedCardWidth + cardGap) -
//                 cardGap +
//                 rightPadding;
//               const updatedScrollDistance = Math.max(
//                 0,
//                 updatedTotalWidth - window.innerWidth
//               );

//               if (container) {
//                 container.style.height =
//                   window.innerWidth <= 768 ? "400px" : "636px";
//               }
//             }
//           },
//         });
//       } catch (error) {
//         console.error("Error creating ScrollTrigger:", error);
//       }
//     }

//     // Handle window resize
//     const handleResize = () => {
//       if (scrollTriggerRef.current) {
//         try {
//           scrollTriggerRef.current.refresh();
//         } catch (error) {
//           console.error("Error refreshing ScrollTrigger:", error);
//         }
//       }
//     };

//     window.addEventListener("resize", handleResize);

//     // Return cleanup function
//     return () => {
//       window.removeEventListener("resize", handleResize);

//       if (scrollTriggerRef.current) {
//         try {
//           scrollTriggerRef.current.kill();
//         } catch (error) {
//           console.error("Error killing ScrollTrigger:", error);
//         }
//         scrollTriggerRef.current = null;
//       }

//       // Reset the position of the track
//       gsap.set(track, { clearProps: "all" });

//       // Reset container height
//       if (container) {
//         container.style.height = "auto";
//       }
//     };
//   }, [imagesLoaded, location]);

//   return (
//     <div className="featured-work">
//       <div className="featured-work-header">
//         <h2>Featured Work</h2>
//         <Button>View More</Button>
//       </div>

//       <div ref={containerRef} className="projects-container">
//         <div ref={trackRef} className="projects-track">
//           {featuredProjects.map((project) => (
//             <div key={project.id} className="project-card">
//               <div className="project-image">
//                 <img src={project.image} alt={project.title} />
//                 <span className="year-tag">{project.year}</span>
//                 <div className="project-info">
//                   <h3 className="project-til">{project.title}</h3>
//                   <div className="tags">
//                     {project.tags.map((tag, tagIndex) => (
//                       <span key={tagIndex} className="tag">
//                         {tag}
//                       </span>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FeaturedWork;

import fwork1 from "../../../assets/images/Fwork1.png";
import fwork2 from "../../../assets/images/Fwork2.png";
import fwork3 from "../../../assets/images/Fwork3.png";
import fwork4 from "../../../assets/images/work4.png";
import fwork5 from "../../../assets/images/work5.png";
import workArrow from "../../../assets/images/workArrow.svg";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Button from "../../../components/button";
import "./FeaturedWork.css";
import { useLocation } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const FeaturedWork = () => {
  const containerRef = useRef(null);
  const trackRef = useRef(null);
  const scrollTriggerRef = useRef(null);
  const location = useLocation();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 992 && window.innerWidth > 768
  );

  const featuredProjects = [
    {
      id: 1,
      title: "Breezeflow",
      image: fwork1,
      tags: ["Software Development", "UX/UI Design"],
      year: "AI",
      link: "https://breezeflow.io/",
    },
    {
      id: 2,
      title: "Scooly",
      image: fwork2,
      tags: ["Software Development", "UX/UI Design"],
      year: "Edtech",
      link: "https://www.myscooly.com",
    },
    {
      id: 3,
      title: "CozyIntro",
      image: fwork3,
      tags: ["Software Development", "UX/UI Design"],
      year: "Social",
      link: "https://cozyintro.breezegpt.io/",
    },
    {
      id: 4,
      title: "Retinalogik",
      image: fwork4,
      tags: ["Software Development", "UX/UI Design"],
      year: "Health",
      link: "https://www.retinalogik.com/",
    },
    {
      id: 5,
      title: "Bountip",
      image: fwork5,
      tags: ["Software Development", "UX/UI Design"],
      year: "Foodtech",
      link: "https://www.bountip.com/",
    },
  ];

  // Handle responsive state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth <= 992 && window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check when all images are loaded
  useEffect(() => {
    const imagePromises = featuredProjects.map((project) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = project.image;
        img.onload = () => resolve();
        img.onerror = () => resolve(); // Handle errors gracefully
      });
    });

    Promise.all(imagePromises).then(() => {
      setImagesLoaded(true);
    });
  }, []);

  // Function to get dimensions based on screen size
  const getDimensions = () => {
    if (window.innerWidth <= 480) {
      // Extra small mobile
      return {
        cardWidth: 250,
        cardHeight: 320,
        cardGap: 16,
        padding: 16,
      };
    } else if (window.innerWidth <= 768) {
      // Mobile
      return {
        cardWidth: 300,
        cardHeight: 400,
        cardGap: 20,
        padding: 20,
      };
    } else if (window.innerWidth <= 992) {
      // Tablet
      return {
        cardWidth: 380,
        cardHeight: 520,
        cardGap: 20,
        padding: 24,
      };
    } else {
      // Desktop
      return {
        cardWidth: 499,
        cardHeight: 636,
        cardGap: 24,
        padding: 40,
      };
    }
  };

  // Initialize or refresh ScrollTrigger
  const initScrollTrigger = () => {
    const container = containerRef.current;
    const track = trackRef.current;

    if (!container || !track) return;

    // Add loaded class to track for fade-in animation
    track.classList.add("loaded");

    const sections = gsap.utils.toArray(".project-card");
    const { cardWidth, cardHeight, cardGap, padding } = getDimensions();

    // Set dimensions for all cards based on current screen size
    sections.forEach((section) => {
      section.style.width = `${cardWidth}px`;
      section.style.height = `${cardHeight}px`;
    });

    // Set container height
    container.style.height = `${cardHeight}px`;

    // Update track padding
    track.style.padding = `0 ${padding}px`;
    track.style.gap = `${cardGap}px`;
    track.style.height = `${cardHeight}px`;

    // Calculate the total width needed for horizontal scrolling
    const totalWidth =
      sections.length * (cardWidth + cardGap) - cardGap + padding;
    const viewportWidth = window.innerWidth;
    const scrollDistance = Math.max(0, totalWidth - viewportWidth);

    // Clear any existing ScrollTrigger instance
    if (scrollTriggerRef.current) {
      try {
        scrollTriggerRef.current.kill();
        scrollTriggerRef.current = null;
      } catch (error) {
        console.error("Error killing previous ScrollTrigger:", error);
      }
    }

    // Only create ScrollTrigger if there's actual scrolling to do
    if (scrollDistance > 0) {
      try {
        scrollTriggerRef.current = ScrollTrigger.create({
          trigger: ".featured-work",
          start: "top top",
          pin: container,
          pinSpacing: true,
          scrub: 1,
          end: `+=${scrollDistance}`,
          invalidateOnRefresh: true,
          animation: gsap.to(track, {
            x: -scrollDistance,
            ease: "none",
          }),
        });
      } catch (error) {
        console.error("Error creating ScrollTrigger:", error);
      }
    } else {
      // Center the content if no scrolling is needed
      gsap.set(track, {
        x: 0,
        left: "50%",
        xPercent: -50,
      });
    }
  };

  // Initialize ScrollTrigger after images are loaded
  useLayoutEffect(() => {
    if (!imagesLoaded) return;

    initScrollTrigger();

    // Handle window resize
    const handleResize = () => {
      try {
        // Use setTimeout to debounce resize events
        if (window.resizeTimeout) {
          clearTimeout(window.resizeTimeout);
        }

        window.resizeTimeout = setTimeout(() => {
          initScrollTrigger();
        }, 250);
      } catch (error) {
        console.error("Error handling resize:", error);
      }
    };

    window.addEventListener("resize", handleResize);

    // Return cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
      if (window.resizeTimeout) {
        clearTimeout(window.resizeTimeout);
      }

      if (scrollTriggerRef.current) {
        try {
          scrollTriggerRef.current.kill();
        } catch (error) {
          console.error("Error cleaning up ScrollTrigger:", error);
        }
        scrollTriggerRef.current = null;
      }

      const track = trackRef.current;
      if (track) {
        gsap.set(track, { clearProps: "all" });
      }

      const container = containerRef.current;
      if (container) {
        container.style.height = "auto";
      }
    };
  }, [imagesLoaded, location]);

  return (
    <div className="featured-work">
      <div className={`featured-work-header ${isMobile ? "mobile" : ""}`}>
        <h2>Featured Work</h2>
        <Button>View More</Button>
      </div>

      <div
        ref={containerRef}
        className={`projects-container ${isMobile ? "mobile" : ""} ${
          isTablet ? "tablet" : ""
        }`}
      >
        <div
          ref={trackRef}
          className={`projects-track ${isMobile ? "mobile" : ""} ${
            isTablet ? "tablet" : ""
          }`}
        >
          {featuredProjects.map((project) => (
            <a href={project?.link} target="_blank" rel="noreferrer">
              <div
                key={project.id}
                className={`project-card ${isMobile ? "mobile" : ""} ${
                  isTablet ? "tablet" : ""
                }`}
              >
                <div className="project-image">
                  <img src={project.image} alt={project.title} />
                  <span className="year-tag">{project.year}</span>
                  <div className="project-info">
                    <h3 className={`project-til ${isMobile ? "mobile" : ""}`}>
                      {project.title}
                    </h3>
                    <div className="tags">
                      {project.tags.map((tag, tagIndex) => (
                        <span
                          key={tagIndex}
                          className={`tag ${isMobile ? "mobile" : ""}`}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <img className="navigationArrow" src={workArrow} alt="" />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedWork;
