import img1 from "../../../assets/images/software-development.svg";
import img2 from "../../../assets/images/sales-development.svg";

import img4 from "../../../assets/images/sales_development.svg";
import img5 from "../../../assets/images/help-support.svg";
export const services = [
  { name: "Golang", color: "#FFF7C2" },
  { name: "React.js", color: "#D1FFDB" },
  { name: "Next.js", color: "#F1E3FF" },
  { name: "Brand guidelines", color: "#D6E9FF" },
  { name: "Branding", color: "#E7D6FF" },
  { name: "Animations", color: "#E5D1B3" },
  { name: "Node.js", color: "#D1FFE3" },
  { name: "Web Development", color: "#FFF7B3" },
  { name: "UI UX Design", color: "#E3D1FF" },
  { name: "Web Apps", color: "#4C9E64" },
  { name: "Mobile App", color: "#FFE7B3" },
  { name: "Website Design", color: "#FFD1D1" },
  { name: "Figma", color: "#E5E7EB" },
];

export const mainServices = [
  {
    name: "Software Development",
    description:
      "Access skilled developers proficient in the latest technologies to scale your business and execute projects with precision.",
    image: img1,
    technologies: [
      { name: "Node.js", icon: "⚡" },
      { name: "JavaScript", icon: "⚡" },
      { name: "TypeScript", icon: "⚡" },
      { name: "Next.js", icon: "⚡" },
      { name: "Supabase", icon: "⚡" },
      { name: "Golang", icon: "⚡" },
      { name: "Express", icon: "⚡" },
      { name: "MongoDB", icon: "⚡" },
    ],
  },
  {
    name: "Sales Development Representatives",
    description:
      "Our SDRs from Ethiopia are trained to engage leads and drive growth with professional communication and results-driven strategies, converting prospects into valuable opportunities.",
    image: img4,
    technologies: [
      { name: "Effective Communication", icon: "⚡" },
      { name: "Prospecting & Research", icon: "⚡" },
      { name: "Time Management & Organization", icon: "⚡" },
      { name: "CRM & Sales Tools Proficiency", icon: "⚡" },
      { name: "Cold Calling & Emailing", icon: "⚡" },
      { name: "Relationship Building", icon: "⚡" },
      { name: "Sales Process Understanding", icon: "⚡" },
      { name: "Sales Discovery & Qualification", icon: "⚡" },
      { name: "Solution Selling", icon: "⚡" },
    ],
  },
  {
    name: "Customer Support Specialists",
    description:
      "Exceptional customer support is crucial to business success. Build trust and loyalty with specialists who handle inquiries, resolve issues, and deliver exceptional customer experiences.",
    image: img5,
    technologies: [
      { name: "Effective Communication", icon: "⚡" },
      { name: "Problem-Solving", icon: "⚡" },
      { name: "Technical Proficiency", icon: "⚡" },
      { name: "Customer Relationship Management", icon: "⚡" },
      { name: "Support Platforms Proficiency", icon: "⚡" },
    ],
  },
];
