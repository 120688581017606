import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import "./index.css";

// Import Swiper styles
// import "swiper/css";
import "swiper/css/navigation";

const testimonials = [
  {
    id: 1,
    name: "Tianna Going",
    reason: "Exceptional Service",
    role: "Co-founder & Co-CEO, LEWK",
    content:
      "BreezeLearn’s team expertise and collaborative approach made the process seamless and they delivered beyond expectations. Their contribution to LEWK has been pivotal in our growth journey.",
  },
  {
    id: 2,
    name: "Jessica Turner",
    role: "CTO, NextGen Solutions",
    reason: "Top Talent, Seamless Hiring",
    content:
      "Finding the right developers to scale our software platform was a real challenge, but BreezeLearn made it effortless. They understood our needs and quickly connected us with developers who perfectly matched our needs. Our timelines have shortened, and the results speak for themselves!",
  },
];

const userLocations = [
  { x: "20%", y: "40%" },
  { x: "30%", y: "30%" },
  { x: "50%", y: "45%" },
  { x: "70%", y: "35%" },
  { x: "80%", y: "50%" },
  { x: "90%", y: "30%" },
];

const TestimonialsSection = () => {
  return (
    <div style={{ backgroundColor: "#F3F5FF" }}>
      <div className="testimonials-section">
        <div className="content-container">
          <div className="left-content">
            <div className="header">
              <h2>
                What Our Customers
                <br />
                Have to Say
              </h2>
              <p>We work hard to support our customers every day.</p>
            </div>

            <div className="stats-container">
              <div className="stat-item">
                <h3>98%</h3>
                <p>Aqua Environment</p>
              </div>

              <div className="stat-item">
                <h3>100%</h3>
                <p>Overall Satisfaction</p>
              </div>
            </div>
          </div>

          <div className="map-container">
            <div className="map-wrapper">
              <div className="dotted-map">
                <ComposableMap
                  projectionConfig={{
                    scale: 140,
                    center: [0, 0],
                  }}
                  width={800}
                  height={400}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Geographies geography={"./countries.json"}>
                    {({ geographies }) =>
                      geographies.map((geo) => (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill="rgba(255, 255, 255, 0.1)"
                          stroke="rgba(255, 255, 255, 0.3)"
                          strokeWidth={0.5}
                          style={{
                            default: {
                              outline: "none",
                            },
                            hover: {
                              outline: "none",
                              fill: "rgba(255, 255, 255, 0.2)",
                            },
                            pressed: {
                              outline: "none",
                            },
                          }}
                        />
                      ))
                    }
                  </Geographies>
                </ComposableMap>
              </div>
              {/* {userLocations.map((location, index) => (
                <div
                  key={index}
                  className="user-avatar"
                  style={{
                    left: location.x,
                    top: location.y,
                  }}
                >
                  <img
                    src="https://cdn.pixabay.com/photo/2022/11/21/15/31/woman-7607337_1280.jpg"
                    alt="User avatar"
                  />
                </div>
              ))} */}
            </div>
          </div>
        </div>

        <div className="testimonials-slider-container">
          <button
            className="slider-arrow prev custom-swiper-button-prev"
            aria-label="Previous testimonials"
          >
            <ChevronLeft size={24} />
          </button>

          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={30}
            slidesPerView={2}
            navigation={{
              prevEl: ".custom-swiper-button-prev",
              nextEl: ".custom-swiper-button-next",
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            className="testimonials-slider"
          >
            {testimonials.map((testimonial) => (
              <SwiperSlide key={testimonial.id}>
                <div className="testimonial-card">
                  <p className="reason">{testimonial.reason}</p>
                  <div className="stars">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        width="17"
                        height="17"
                        viewBox="0 0 20 20"
                        fill="#FFB400"
                      >
                        <path d="M10 1l2.5 6h6.5l-5 4.5 2 6.5-6-4.5-6 4.5 2-6.5-5-4.5h6.5z" />
                      </svg>
                    ))}
                  </div>
                  <p className="content">{testimonial.content}</p>
                  <div className="author">
                    <h4>{testimonial.name}</h4>
                    <p>{testimonial.role}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <button
            className="slider-arrow next custom-swiper-button-next"
            aria-label="Next testimonials"
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;
