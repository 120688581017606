import React from "react";

const StepTwoIcon = ({ fill }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_590_1383)">
        <path
          d="M22 9.1665C24.2782 9.1665 26.125 7.31968 26.125 5.0415C26.125 2.76333 24.2782 0.916504 22 0.916504C19.7218 0.916504 17.875 2.76333 17.875 5.0415C17.875 7.31968 19.7218 9.1665 22 9.1665Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.25 17.4166C29.7825 15.5834 28.7178 13.9581 27.2238 12.7973C25.7299 11.6365 23.8919 11.0063 22 11.0063C20.1081 11.0063 18.2701 11.6365 16.7762 12.7973C15.2822 13.9581 14.2175 15.5834 13.75 17.4166H30.25Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.1665 34.8335C11.4447 34.8335 13.2915 32.9867 13.2915 30.7085C13.2915 28.4303 11.4447 26.5835 9.1665 26.5835C6.88833 26.5835 5.0415 28.4303 5.0415 30.7085C5.0415 32.9867 6.88833 34.8335 9.1665 34.8335Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.4165 43.0831C16.949 41.2499 15.8843 39.6246 14.3903 38.4638C12.8964 37.303 11.0584 36.6729 9.1665 36.6729C7.27461 36.6729 5.4366 37.303 3.94266 38.4638C2.44873 39.6246 1.38398 41.2499 0.916504 43.0831H17.4165Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.8335 34.8335C37.1117 34.8335 38.9585 32.9867 38.9585 30.7085C38.9585 28.4303 37.1117 26.5835 34.8335 26.5835C32.5553 26.5835 30.7085 28.4303 30.7085 30.7085C30.7085 32.9867 32.5553 34.8335 34.8335 34.8335Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M43.0835 43.0831C42.616 41.2499 41.5513 39.6246 40.0573 38.4638C38.5634 37.303 36.7254 36.6729 34.8335 36.6729C32.9416 36.6729 31.1036 37.303 29.6097 38.4638C28.1157 39.6246 27.051 41.2499 26.5835 43.0831H43.0835Z"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.5 34.6831C23.9712 36.1052 20.0288 36.1052 16.5 34.6831"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.25 8.95752C32.4831 10.4792 34.2531 12.5875 35.3652 15.0504C36.4772 17.5132 36.8883 20.2351 36.553 22.9165"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.75 8.95752C11.5169 10.4792 9.74687 12.5875 8.63481 15.0504C7.52274 17.5132 7.11171 20.2351 7.44697 22.9165"
          stroke={fill}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_590_1383">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StepTwoIcon;
