import React from "react";

const StepThreeIcon = ({ fill }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.4775 42.3718C31.7606 41.5036 30.8721 40.793 29.8675 40.2844C28.863 39.7758 27.7643 39.4804 26.6402 39.4165H17.4735C16.3456 39.4808 15.2422 39.7718 14.2291 40.272C13.2161 40.7722 12.3143 41.4714 11.5775 42.3278L11 43.0832H33L32.4775 42.3718Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.9208 17.3396C28.9588 17.1768 28.9516 17.0067 28.8999 16.8477C28.8482 16.6887 28.754 16.5469 28.6275 16.4376C28.501 16.3282 28.347 16.2556 28.1822 16.2275C28.0174 16.1994 27.8481 16.2169 27.6925 16.2781C24.0369 17.7481 19.9537 17.7428 16.302 16.2635C16.1467 16.2036 15.978 16.1872 15.8141 16.2159C15.6501 16.2446 15.4971 16.3175 15.3714 16.4266C15.2457 16.5357 15.1521 16.677 15.1006 16.8352C15.0491 16.9935 15.0417 17.1628 15.0791 17.325L20.1666 39.3763H23.8333L28.9208 17.3396Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 11.9165C25.0376 11.9165 27.5 9.45407 27.5 6.4165C27.5 3.37894 25.0376 0.916504 22 0.916504C18.9624 0.916504 16.5 3.37894 16.5 6.4165C16.5 9.45407 18.9624 11.9165 22 11.9165Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.6788 22.02L32.3728 22.8139C32.7946 22.9945 33.1705 23.2675 33.4727 23.6128C33.7748 23.9581 33.9957 24.3668 34.1188 24.8088C34.242 25.2508 34.2644 25.7148 34.1843 26.1666C34.1042 26.6184 33.9237 27.0464 33.6562 27.4192L25.6665 39.3799"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3211 22.02L11.6271 22.8139C11.2053 22.9945 10.8294 23.2675 10.5272 23.6128C10.2251 23.9581 10.0042 24.3668 9.88106 24.8088C9.75789 25.2508 9.73552 25.7148 9.8156 26.1666C9.89567 26.6184 10.0762 27.0464 10.3437 27.4192L18.3334 39.3799"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default StepThreeIcon;
