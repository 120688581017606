import React from "react";
import "./mobileHowItWorks.scss";
import { cog_settings } from "../../../assets/images";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const MobileHowItWorks = () => {
  const data = [
    "Tell us about the roles you're looking to fill.",
    "We provide pre-vetted professionals that fit your requirements.",
    "Choose from a curated list of top talent.",
    "We ensure a smooth onboarding process.",
    "BreezeLearn offers Ongoing assistance to help your team thrive.",
  ];

  // GSAP animations
  let sections = gsap.utils.toArray(".mobile_how_it_works_body");
  sections.forEach((service, index) => {
    gsap.fromTo(
      service,
      { opacity: index == 0 ? 1 : 0, y: index == 0 ? 0 : 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power1.out",
        scrollTrigger: {
          trigger: service,
          start: "top bottom",
          end: "top 30px",
          scrub: 0.1,
          toggleActions: "play reverse play reverse",
        },
      }
    );
  });

  return (
    <div className="mobile_how_it_works_outside_body">
      {data?.map((service, index) => (
        <div className="mobile_how_it_works_body">
          <div className="mobile_how_it_works_body_index">{index + 1}</div>
          <div className="mobile_how_it_works_body_icon">
            <img src={cog_settings} />
          </div>
          <div className="mobile_how_it_works_body_description">{service}</div>
        </div>
      ))}
    </div>
  );
};

export default MobileHowItWorks;
