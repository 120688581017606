import React from "react";
import { motion } from "framer-motion";
import "./ProductSection.scss";
import comWorkWith1 from "../../../assets/images/comWorkWith1.png";
import comWorkWith2 from "../../../assets/images/comWorkWith2.png";
import comWorkWith3 from "../../../assets/images/comWorkWith3.png";
import comWorkWith4 from "../../../assets/images/comWorkWith4.png";
import comWorkWith5 from "../../../assets/images/comWorkWith5.png";

const ProductSection = () => {
  // Create a bounce animation for each logo with different durations
  const bounceAnimation = (delay = 0, duration = 3) => ({
    y: [0, -15, 0],
    transition: {
      delay,
      duration,
      ease: "easeInOut",
      times: [0, 0.5, 1],
      repeat: Infinity,
      repeatType: "loop",
    },
  });

  return (
    <section className="product-section">
      <h2>
        We've built products for innovative businesses
        <br /> ranging across multiple industries
      </h2>
      <div className="productImges">
        <motion.img
          src={comWorkWith1}
          className="brandImg1"
          alt="Brand Lewk"
          animate={bounceAnimation(0, 4)}
        />

        <motion.img
          src={comWorkWith2}
          className="brandImg2"
          alt="Brand myFertility labs"
          animate={bounceAnimation(0.8, 3.6)}
        />

        <motion.img
          src={comWorkWith3}
          className="brandImg3"
          alt="Brand Retinalogik"
          animate={bounceAnimation(0.4, 4.2)}
        />

        <motion.img
          src={comWorkWith4}
          className="brandImg4"
          alt="Brand Scooly"
          animate={bounceAnimation(1.2, 3.8)}
        />

        <motion.img
          src={comWorkWith5}
          className="brandImg5"
          alt="Brand Bountip"
          animate={bounceAnimation(0.6, 4.4)}
        />
      </div>
    </section>
  );
};

export default ProductSection;
