import "./index.scss";
import React, { useEffect, useRef, useState } from "react";
import {
  Engine,
  Render,
  Runner,
  Common,
  MouseConstraint,
  Mouse,
  Composite,
  Bodies,
  Body,
  Events,
} from "matter-js";
const logoCircled = process.env.PUBLIC_URL + "/logoCircled.png";

const PhysicsServices = () => {
  const sceneRef = useRef(null);
  const engineRef = useRef(null);
  const runnerRef = useRef(null);
  const renderRef = useRef(null);
  const logoBodyRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1101);

  const services = [
    { name: "Golang", color: "#FCFE78", textColor: "#07143F" },
    { name: "React.js", color: "#86FFA4", textColor: "#07143F" },
    { name: "Branding", color: "#8D5FED", textColor: "#FFFFFF" },
    { name: "Framer Motion", color: "#AE7237", textColor: "#FFFFFF" },
    { name: "NextJS", color: "#E3C2FB", textColor: "#07143F" },
    { name: "Figma", color: "#1846E3", textColor: "#ffff" },
    { name: "Website Design", color: "#FF6D61", textColor: "#ffff" },
    { name: "Mobile Apps", color: "#FCFE78", textColor: "#07143F" },
    { name: "Web Development", color: "#D1F86D", textColor: "#07143F" },
    { name: "Web Apps", color: "#3D8531", textColor: "#ffff" },
    { name: "Node.js", color: "#CCFDF1", textColor: "#07143F" },
    { name: "UI/UX Design", color: "#8D5FED", textColor: "#ffff" },
    { name: "Java", color: "#E3C2FB", textColor: "#07143F" },
    { name: "Brand guidelines", color: "#1847E3", textColor: "#fff" },
  ];

  useEffect(() => {
    // Handle responsive state and resize events
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth <= 1101);
    };

    window.addEventListener("resize", handleWindowResize);

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (sceneRef.current) {
      observer.observe(sceneRef.current);
    }

    const parent = sceneRef.current.parentElement;
    const width = parent.clientWidth;
    const height = parent.clientHeight;

    engineRef.current = Engine.create();
    const world = engineRef.current.world;

    renderRef.current = Render.create({
      element: sceneRef.current,
      engine: engineRef.current,
      options: {
        width: width,
        height: height,
        wireframes: false,
        background: "transparent",
      },
    });

    runnerRef.current = Runner.create();

    // Create service bodies with adjusted sizes for mobile
    const serviceBodies = services.map((service) => {
      // Adjust pill size for mobile
      const fontSize = isMobile ? 10 : 12;
      const padding = isMobile ? 30 : 40;
      const width = service.name.length * fontSize + padding;
      const height = isMobile ? 30 : 40;

      const x = parent.clientWidth / 2;
      const y = parent.clientHeight + 100;

      const pill = Bodies.rectangle(x, y, width, height, {
        chamfer: { radius: height / 2 },
        render: {
          fillStyle: service.color,
        },
        restitution: 0.7,
        friction: 0.01,
        label: service.name,
      });

      return pill;
    });

    const walls = [
      Bodies.rectangle(width / 2, 0, width, 5, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }),
      Bodies.rectangle(width / 2, height, width, 5, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }),
      Bodies.rectangle(width, height / 2, 5, height, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }),
      Bodies.rectangle(0, height / 2, 5, height, {
        isStatic: true,
        render: { fillStyle: "transparent" },
      }),
    ];

    const mouse = Mouse.create(renderRef.current.canvas);
    const mouseConstraint = MouseConstraint.create(engineRef.current, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

    // First add everything except the logo
    Composite.add(world, [...serviceBodies, ...walls, mouseConstraint]);

    // Load logo image and create the logo body only after image is loaded
    const logoImg = new Image();
    logoImg.onload = () => {
      // Adjust logo size for mobile
      const logoSize = isMobile ? 40 : 50;
      const logoScale = isMobile ? 60 / logoImg.width : 80 / logoImg.width;

      const logoBody = Bodies.circle(width / 2, height / 2, logoSize, {
        render: {
          sprite: {
            texture: logoCircled,
            xScale: logoScale,
            yScale: logoScale,
          },
        },
        restitution: 0.7,
        friction: 0.01,
        label: "logo",
      });

      logoBodyRef.current = logoBody;
      Composite.add(world, [logoBody]);

      // If already in view, position the logo in center
      if (isInView) {
        Body.setPosition(logoBody, {
          x: parent.clientWidth / 2,
          y: parent.clientHeight / 2,
        });
      }
    };
    logoImg.src = logoCircled;

    renderRef.current.mouse = mouse;

    Events.on(renderRef.current, "afterRender", () => {
      const context = renderRef.current.context;
      // Adjust font size for mobile
      const fontSize = isMobile ? "11px" : "13px";
      context.font = `${fontSize} Arial`;
      context.fillStyle = "#07143F";
      context.textAlign = "center";
      context.textBaseline = "middle";

      serviceBodies.forEach((body) => {
        const service = services.find((s) => s.name === body.label);
        const { x, y } = body.position;
        context.save();
        context.fillStyle = service?.textColor || "black"; // Apply text color
        context.translate(x, y);
        context.rotate(body.angle);
        context.fillText(body.label, 0, 0);
        context.restore();
      });
    });

    // Handle canvas resize
    const handleCanvasResize = () => {
      const newWidth = parent.clientWidth;
      const newHeight = parent.clientHeight;

      renderRef.current.canvas.width = newWidth;
      renderRef.current.canvas.height = newHeight;
      renderRef.current.options.width = newWidth;
      renderRef.current.options.height = newHeight;

      walls[0].position.x = newWidth / 2;
      walls[1].position.x = newWidth / 2;
      walls[1].position.y = newHeight;
      walls[2].position.x = newWidth;
      walls[2].position.y = newHeight / 2;
      walls[3].position.y = newHeight / 2;

      Body.setVertices(
        walls[0],
        Bodies.rectangle(newWidth / 2, 0, newWidth, 50).vertices
      );
      Body.setVertices(
        walls[1],
        Bodies.rectangle(newWidth / 2, newHeight, newWidth, 50).vertices
      );
      Body.setVertices(
        walls[2],
        Bodies.rectangle(newWidth, newHeight / 2, 50, newHeight).vertices
      );
      Body.setVertices(
        walls[3],
        Bodies.rectangle(0, newHeight / 2, 50, newHeight).vertices
      );
    };

    window.addEventListener("resize", handleCanvasResize);

    if (isInView) {
      Runner.run(runnerRef.current, engineRef.current);
      Render.run(renderRef.current);

      // Position logo in center if it exists
      if (logoBodyRef.current) {
        Body.setPosition(logoBodyRef.current, {
          x: parent.clientWidth / 2,
          y: parent.clientHeight / 2,
        });
      }

      // Animate service pills around the logo
      serviceBodies.forEach((pill, index) => {
        // Adjust radius based on screen size
        const radius =
          Math.min(parent.clientWidth, parent.clientHeight) /
          (isMobile ? 4 : 3);
        const angle = (index / services.length) * Math.PI * 2;
        const targetX = parent.clientWidth / 2 + radius * Math.cos(angle);
        const targetY = parent.clientHeight / 2 + radius * Math.sin(angle);

        Body.setPosition(pill, { x: targetX, y: targetY });
        Body.setVelocity(pill, {
          x: Common.random(-2, 2),
          y: Common.random(-2, 2),
        });
      });
    } else {
      Runner.stop(runnerRef.current);
      Render.stop(renderRef.current);
    }

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("resize", handleCanvasResize);
      Events.off(renderRef.current, "afterRender");
      Render.stop(renderRef.current);
      Runner.stop(runnerRef.current);
      Engine.clear(engineRef.current);
      if (renderRef.current?.canvas) {
        renderRef.current.canvas.remove();
      }
    };
  }, [isInView, isMobile]);

  useEffect(() => {
    if (isInView && logoBodyRef.current) {
      const parent = sceneRef.current.parentElement;
      // Position logo in center when component comes into view
      Body.setPosition(logoBodyRef.current, {
        x: parent.clientWidth / 2,
        y: parent.clientHeight / 2,
      });
    }
  }, [isInView]);

  return (
    <section className="services">
      <h2 style={{ textAlign: "center" }}>Services</h2>
      <div ref={sceneRef} style={{ width: "100%", height: "100%" }} />
    </section>
  );
};

export default PhysicsServices;
