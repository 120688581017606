import { useEffect, useState, useRef } from "react";
import "./howItWorks.scss";
import StepOneIcon from "../../../assets/images/StepOneIcon";
import { howItWorksIcon } from "../../../assets/images";
import StepTwoIcon from "../../../assets/images/StepTwoIcon";
import StepThreeIcon from "../../../assets/images/StepThreeIcon";
import StepFourIcon from "../../../assets/images/StepFourIcon";
import StepFiveIcon from "../../../assets/images/StepFiveIcon";

const steps = [
  {
    id: 1,
    text: "Tell us about the roles you’re looking to fill.",
    icon: StepOneIcon,
  },
  {
    id: 2,
    text: "We provide pre-vetted professionals that fit your requirements.",
    icon: StepTwoIcon,
  },
  {
    id: 3,
    text: "You review candidates and make the best choice.",
    icon: StepThreeIcon,
  },
  {
    id: 4,
    text: "Onboarding support is provided to ensure a smooth start.",
    icon: StepFourIcon,
  },
  {
    id: 5,
    text: "BreezeLearn offers ongoing assistance to help your team thrive.",
    icon: StepFiveIcon,
  },
];

const HowItWorks = () => {
  const [completedSteps, setCompletedSteps] = useState([]); // Tracks completed steps
  const stepRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const stepIndex = stepRefs.current.findIndex(
            (el) => el === entry.target
          );

          if (entry.isIntersecting) {
            setCompletedSteps((prevSteps) => {
              const nextStepToUnlock = prevSteps.length;

              if (
                stepIndex === nextStepToUnlock &&
                !prevSteps.includes(stepIndex)
              ) {
                return [...prevSteps, stepIndex];
              }
              return prevSteps;
            });
          }
        });
      },
      { threshold: 0.6 }
    );

    stepRefs.current.forEach((step) => {
      if (step) observer.observe(step);
    });

    return () => {
      stepRefs.current.forEach((step) => {
        if (step) observer.unobserve(step);
      });
    };
  }, [completedSteps]);

  const connectorColors = [
    "#007bff",
    "#007bff",
    "#007bff",
    "#007bff",
    "#007bff",
  ];

  return (
    <div className="how-it-works-container">
      <div className="how-it-works-header">
        <div className="how-it-works-header-icon">
          <img src={howItWorksIcon} />
        </div>
        <h2 className="how-it-works-title">How It Works</h2>
      </div>

      <div className="steps-wrapper">
        {/* SVG Curved Paths for Connectors */}
        <svg className="svg-container" viewBox="0 0 700 700">
          {/* Arc 1: Starts at 10° (Top Right) */}
          <path
            d="M370 50 A300 300 0 0 1 650 330"
            stroke={completedSteps.includes(0) ? connectorColors[0] : "#f3f5ff"}
            strokeWidth="14"
            fill="transparent"
          />

          {/* Arc 2: Covers 80° (Bottom Right) */}
          <path
            d="M650 330 A300 300 0 0 1 520 620"
            stroke={completedSteps.includes(1) ? connectorColors[1] : "#f3f5ff"}
            strokeWidth="14"
            fill="transparent"
          />

          {/* Arc 3: Covers 80° (Bottom Left) */}
          <path
            d="M520 620 A300 300 0 0 1 180 620"
            stroke={completedSteps.includes(2) ? connectorColors[2] : "#f3f5ff"}
            strokeWidth="14"
            fill="transparent"
          />

          {/* Arc 4: Covers 80° (Top Left, stops before 360°) */}
          <path
            d="M180 620 A300 300 0 0 1 50 350"
            stroke={completedSteps.includes(3) ? connectorColors[3] : "#f3f5ff"}
            strokeWidth="14"
            fill="transparent"
          />
        </svg>

        {steps.map((step, index) => (
          <div
            key={step.id}
            ref={(el) => (stepRefs.current[index] = el)}
            className={`step ${
              completedSteps.includes(index) ? `completed-${index + 1}` : ""
            }`}
          >
            <div className="how_it_works_icon">
              <step.icon
                fill={completedSteps.includes(index) ? `#fff` : "#07143F"}
              />
            </div>
            <div className="step-number">{step.id}</div>
            <p className="step-text">{step.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
