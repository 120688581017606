export const ArrowRightIcon = () => {
  return (
    <svg
      width="25"
      height="30"
      viewBox="0 0 25 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0698 15.0276L0 0H12.396L24.9008 15.0276L12.396 30H0L12.0698 15.0276Z"
        fill="white"
      />
    </svg>
  );
};
