import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import "./index.css";
const publicRoute = process.env.PUBLIC_URL;
const heroImg1 = publicRoute + "/heroImg1.png";
const heroImg2 = publicRoute + "/heroImg2.png";
const heroImg3 = publicRoute + "/heroImg3.png";

const Hero = ({ onAnimationComplete }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -200px 0px",
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.1,
        onComplete: () => {
          // Call the callback after all hero animations are complete
          setTimeout(() => onAnimationComplete(), 500); // Add a small delay before starting hire card
        },
      },
    },
  };

  // Rest of the variants remain the same...
  const imageVariants = {
    hidden: (direction) => ({
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "bottom" ? 100 : 0,
      opacity: 0,
    }),
    visible: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const contentVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <div className="hero" ref={ref}>
      <motion.div
        className="hero-container"
        variants={containerVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        {/* Component content remains the same... */}
        {/* Left Image */}
        <motion.div
          className="hero-image hero-image-left"
          variants={imageVariants}
          custom="left"
        >
          <img src={heroImg3} alt="Person working" />
        </motion.div>

        {/* Right Image */}
        <motion.div
          className="hero-image hero-image-right"
          variants={imageVariants}
          custom="right"
        >
          <img src={heroImg1} alt="Person at desk" />
        </motion.div>

        {/* Bottom Image */}
        <motion.div
          className="hero-image hero-image-bottom"
          variants={imageVariants}
          custom="bottom"
        >
          <img src={heroImg2} alt="Person with laptop" />
        </motion.div>

        {/* Content */}
        <motion.div
          className="hero-content"
          // variants={contentVariants}
        >
          <motion.h1
            className="hero-title"
            variants={{
              hidden: { y: 20, opacity: 0 },
              visible: {
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 150, // Faster movement
                  damping: 5, // More bounce
                  bounce: 0.6, // Intense bounce effect
                  delay: 0.3,
                },
              },
            }}
          >
            Connect Your Business <br className="herobr" />
            with the{" "}
            <span className="highlight-wrapper">
              Right Talent
              <motion.svg
                viewBox="0 0 255 20"
                className="highlight-svg"
                variants={{
                  hidden: { pathLength: 0 },
                  visible: {
                    pathLength: 1,
                    transition: { duration: 1, delay: 0.8 },
                  },
                }}
              >
                <motion.path
                  d="M 0 17 Q 125 -5 255 17"
                  fill="none"
                  stroke="#67e8f9"
                  strokeWidth="8"
                  strokeLinecap="round"
                  initial={{ pathLength: 0 }}
                  animate={{
                    pathLength: 1,
                    transition: {
                      duration: 0.3,
                      ease: "easeInOut",
                      delay: 0.5,
                    },
                  }}
                />
              </motion.svg>
            </span>
            , Fast
          </motion.h1>

          <motion.p
            className="hero-description"
            variants={{
              hidden: { y: 20, opacity: 0 },
              visible: {
                y: 0,
                opacity: 1,
                transition: { duration: 0.5, delay: 0.5 },
              },
            }}
          >
            We help North American businesses connect with skilled professionals
            in sales, customer support, and software development— grow quickly
            and
            <br className="herobr" /> confidently.
          </motion.p>

          <motion.button
            className="hero-button"
            variants={{
              hidden: { scale: 0.9, opacity: 0 },
              visible: {
                scale: 1,
                opacity: 1,
                transition: { duration: 0.5, delay: 0.7 },
              },
            }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              window.location.href =
                "https://doodle.com/bp/nikbedi/meeting-with-breezelearn";
            }}
          >
            Get started
          </motion.button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Hero;
