import React from "react";
import { motion } from "framer-motion";
import { Facebook, Instagram, Linkedin } from "lucide-react";
import footerImg from "../../../assets/images/draw-scene_02.svg";
import "./index.css";
import { routes } from "../navbar/routes";
import { useHistory, useLocation } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handVariants = {
    initial: { y: 20, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const starVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const handleNavigation = (link) => {
    if (link) {
      history.push(link);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  return (
    <footer className="footer">
      <div className="hire-banner">
        <div className="banner-content">
          <h1 className="banner-title">Hire Fast, Hire Smart</h1>
          <button
            onClick={() => {
              window.location.href =
                "https://doodle.com/bp/nikbedi/meeting-with-breezelearn";
            }}
            className="book-call-btn"
          >
            Book a Call
          </button>
        </div>
        <motion.div
          className="banner-illustration"
          initial="initial"
          animate="animate"
        >
          <img src={footerImg} alt="" />
        </motion.div>
      </div>

      <div className="newsletter-section">
        <h2 className="newsletter-title">Newsletter</h2>
        <p className="newsletter-subtitle">
          Signup for offers, sneak-peeks, updates and more
        </p>
        <div className="email-input-container">
          <input
            type="email"
            placeholder="Enter your email"
            className="email-input"
          />
          <button className="subscribe-button">Subscribe</button>
        </div>
      </div>

      <nav className="footer-nav">
        <p
          onClick={() => {
            handleNavigation(routes.home);
          }}
          className={`footer-nav-link ${
            pathname === routes.home ? "footer-link_active" : ""
          } `}
        >
          Home
        </p>
        <p
          className={`footer-nav-link ${
            pathname === routes.about ? "footer-link_active" : ""
          } `}
          onClick={() => {
            handleNavigation(routes.about);
          }}
        >
          About Us
        </p>
        <p
          className={`footer-nav-link ${
            pathname === routes.services ? "footer-link_active" : ""
          } `}
          onClick={() => {
            handleNavigation(routes.services);
          }}
        >
          Services
        </p>
        <p
          className={`footer-nav-link ${
            pathname === routes.training ? "footer-link_active" : ""
          } `}
          onClick={() => {
            handleNavigation(routes.training);
          }}
        >
          Training Programs
        </p>
        <p
          className={`footer-nav-link ${
            pathname === routes.hireFTO ? "footer-link_active" : ""
          } `}
          onClick={() => {
            handleNavigation(routes.hireFTO);
          }}
        >
          Hire a Fractional CTO
        </p>
      </nav>

      <div className="social-links">
        {/* <motion.a
          href="https://facebook.com"
          className="social-link"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <Facebook size={20} />
        </motion.a> */}
        {/* <motion.a
          href="https://instagram.com"
          className="social-link"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <Instagram size={20} />
        </motion.a> */}
        <motion.a
          href="https://www.linkedin.com/company/breezelearn/"
          target="_blank"
          className="social-link"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <Linkedin size={20} />
        </motion.a>
      </div>

      <div className="footer-bottom">
        <p className="copyright">© BreezeLearn 2025 - All rights reserved.</p>
        <div className="legal-links">
          {/* <a href="/terms" className="legal-link">
            Terms & condition
          </a> */}
          <p
            className="legal-link"
            style={{ cursor: "pointer" }}
            onClick={handleNavigation}
          >
            Back to Top
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
