import React, { useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import { DollarSign, Users, Zap } from "lucide-react";
import "./hire.css";
import hireIcon1 from "../../../assets/images/hireIcon1.svg";
import hireIcon2 from "../../../assets/images/hireIcon2.svg";
import hireIcon3 from "../../../assets/images/hireIcon3.svg";

const HireCard = ({ shouldStartAnimation = false }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -100px 0px",
  });

  // Only start animations when both in view AND hero animations are complete
  const shouldAnimate = shouldStartAnimation;

  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  // Rest of the variants remain the same...
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const featureVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const features = [
    {
      icon: hireIcon1,
      title: "Affordable, High-Impact",
      description:
        "Top talent at competitive rates to help your business grow without compromise.",
    },
    {
      icon: hireIcon2,
      title: "Fast Hiring Process",
      description:
        "Connect with the right talent through our streamlined process and build your team in just 14 days.",
    },
    {
      icon: hireIcon1,
      title: "Tailored Solutions",
      description:
        "Flexible, scalable talent options for full-time or project-based needs.",
    },
  ];

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={shouldAnimate ? "visible" : "hidden"}
      variants={cardVariants}
      className="hire-card"
    >
      {/* Rest of the component remains the same... */}
      <div className="hire-card-header">
        <motion.h2
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: { duration: 0.6, delay: 0.2 },
            },
          }}
        >
          Hire Top 1% of Talent
        </motion.h2>
        <motion.p
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: { duration: 0.6, delay: 0.3 },
            },
          }}
        >
          Sales Development Representatives, Customer Support Specialists, and
          Software <br />
          Development Professionals
        </motion.p>
      </div>

      <motion.div className="features-grid" variants={containerVariants}>
        {features.map((feature, index) => (
          <motion.div
            key={index}
            variants={featureVariants}
            className="feature-card"
          >
            <motion.div
              className="feature-icon-wrapper"
              whileHover={{ scale: 1.1 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            >
              <img style={{ cursor: "pointer" }} src={feature.icon} alt="" />
            </motion.div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </motion.div>
        ))}
      </motion.div>

      <motion.div
        className="cta-button-wrapper"
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: {
            opacity: 1,
            y: 0,
            transition: { delay: 0.8, duration: 0.6 },
          },
        }}
      >
        <motion.button
          className="cta-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
          onClick={() => {
            window.location.href =
              "https://doodle.com/bp/nikbedi/meeting-with-breezelearn";
          }}
        >
          Get started
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default HireCard;
