import React, { useState } from "react";

import "./leftNav.scss";
import { NavLink, useLocation, Link } from "react-router-dom";
import { epertiseListImg } from "../../../assets/images";
import { useHistory } from "react-router-dom";

const LeftNav = ({
  open,
  openLogoutBtn,
  closeLogoutBtn,
  openLogoutPage,
  closeNavBar,
}) => {
  const location = useLocation();
  const history = useHistory(); // useHistory instead of useNavigate
  const { pathname } = location;

  const splitLocation = pathname.split("/");
  const handleNavigation = (link) => {
    history.push(link);
    closeNavBar();
  };
  const routes = Object.freeze({
    home: "/",
    about: "/about",
    services: "/services",
    training: "/training",
    hireFTO: "/hire_FCTO",
    contact_us: "/contact_us",
  });

  return (
    <>
      <div
        open={open}
        style={{
          transform: `${open ? "translateX(0)" : "translateX(-100%)"}`,
          transition: "transform 0.3s ease-in-out",
        }}
        className="sidemenu-family-cont"
      >
        <div className="top-navbar-rel">
          <div className="cont-main">
            <div
              onClick={() => {
                handleNavigation(routes.home);
              }}
              className={
                splitLocation[1] === ""
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Home
            </div>
            <div
              onClick={() => {
                handleNavigation(routes.about);
              }}
              className={
                splitLocation[1] === "about"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              About us
            </div>
            <div
              onClick={() => {
                handleNavigation(routes.services);
              }}
              className={
                splitLocation[1] === "services"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Services
            </div>
            <div
              onClick={() => {
                handleNavigation(routes.training);
              }}
              className={
                splitLocation[1] === "training"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Training Programs
            </div>
            <div
              onClick={() => {
                handleNavigation(routes.hireFTO);
              }}
              className={
                splitLocation[1] === "hire_FCTO"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Hire a fCTO
            </div>
            <div
              onClick={() => {
                handleNavigation(routes.contact_us);
              }}
              className={
                splitLocation[1] === "contact_us"
                  ? "leftNavbar-opt-active"
                  : "leftNavbar-opt"
              }
              style={{ textDecoration: "none" }}
            >
              Contact Us
            </div>

            <div
              style={{ textDecoration: "none", paddingBottom: "300px" }}
            ></div>
          </div>
        </div>
        <div className="sidemenu-family-cont_buttom_img">
          <img src={epertiseListImg} />
        </div>
      </div>
    </>
  );
};

export default LeftNav;
