import React from "react";

const StepFourIcon = ({ fill }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8333 22.9165H32.9999"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.0001 6.4165H32.0834C31.111 6.4165 30.1783 6.80281 29.4907 7.49045C28.8031 8.17808 28.4167 9.11071 28.4167 10.0832V33.9165C28.4167 34.889 28.8031 35.8216 29.4907 36.5092C30.1783 37.1969 31.111 37.5832 32.0834 37.5832H33.0001"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.6667 7.33333L38.5001 9.16667L43.0834 5.5"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.6667 23.8333L38.5001 25.6667L43.0834 22"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M36.6667 34.8335L42.1667 40.3335"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.1667 34.8335L36.6667 40.3335"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6666 43.0833L15.5833 32.0833H20.1666V25.6667C20.1666 23.2355 19.2008 20.9039 17.4817 19.1849C15.7626 17.4658 13.4311 16.5 10.9999 16.5C8.56877 16.5 6.23719 17.4658 4.51811 19.1849C2.79902 20.9039 1.83325 23.2355 1.83325 25.6667V32.0833H6.41659L7.33325 43.0833H14.6666Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9999 13.7498C14.5437 13.7498 17.4166 10.877 17.4166 7.33317C17.4166 3.78934 14.5437 0.916504 10.9999 0.916504C7.45609 0.916504 4.58325 3.78934 4.58325 7.33317C4.58325 10.877 7.45609 13.7498 10.9999 13.7498Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default StepFourIcon;
