import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Plus, X } from "lucide-react";
import "./index.css";
import Button from "../../button";
import { useHistory } from "react-router-dom";

const FAQSection = ({ containerStyle }) => {
  const history = useHistory();
  const [openIndex, setOpenIndex] = React.useState(null);

  const faqData = [
    {
      question: "What industries do you serve?",
      answer:
        "BreezeLearn specializes in connecting businesses with top-tier tech talent across multiple industries, including technology, sales, customer support, finance, healthcare, and e-commerce. Our professionals are skilled in software development, AI & machine learning, cybersecurity, digital marketing, and more. If you have specific industry needs, we can tailor our talent search to match your requirements.",
    },
    {
      question: "How do I get started with BreezeLearn?",
      answer:
        "Getting started with BreezeLearn is simple. Fill out the contact form on our website, and one of our dedicated representatives will reach out to you within 24 hours to schedule a consultation. During this consultation, we'll discuss your business needs, project scope, and timelines to create a customized plan that aligns with your goals.",
    },
    {
      question: "Are your professionals remote-ready?",
      answer:
        "Yes, all BreezeLearn professionals are fully equipped for remote work. Our talent is experienced in using modern collaboration tools and project management platforms. Additionally, we ensure that all professionals have access to reliable internet connections and the necessary hardware to deliver seamless remote work experiences. Over 90% of our clients report high satisfaction with the remote readiness of our talent.",
    },
    {
      question: "How long does it take to hire talent?",
      answer:
        "Our streamlined hiring process is designed for efficiency. On average, businesses can onboard talent within 7 to 14 business days, depending on the complexity of the role and specific requirements. We prioritize speed without compromising on quality, ensuring that you get the right talent when you need it.",
    },

    {
      question: "What kind of support does BreezeLearn provide after hiring?",
      answer: `BreezeLearn is committed to your long-term success. After hiring, we provide continuous post-hiring support, including:
      <ul>
        <li>Onboarding assistance to ensure smooth integration into your team.</li>
        <li>Regular check-ins to address any concerns or challenges.</li>
        <li>Performance monitoring to ensure the talent meets your expectations.</li>
        <li>Replacement guarantee incase the hire isn’t the right fit.</li>
      </ul>
     <p>Our support team is available 24/7 to assist with any issues, ensuring a seamless experience for both businesses and professionals.</p>

      `,
    },
    {
      question: "Do you provide project-based talent?",
      answer:
        "Yes, we cater to both <strong>short-term project-based roles</strong> and <strong>long-term full-time positions.</strong> Whether you need a developer for a one-time software build or a customer support team for seasonal demand, we can match you with the right professionals. Over 40% of our clients hire talent for project-based needs, and we ensure the same level of quality and vetting for all engagement.",
    },
    {
      question: "How do you vet your talent?",
      answer: `We follow a <strong>multi-stage vetting process</strong> that ensures high-quality talent:
      <ol>
        <li><strong>Skill Assessments</strong> – Role-specific technical and behavioral tests.</li>
        <li><strong>Background Checks</strong> – Verification of experience, education, and references.</li>
        <li><strong>Live Interviews</strong> – One-on-one discussions to evaluate communication and problem-solving abilities.</li>
        <li><strong>Test Projects (if applicable)</strong> – Hands-on assignments for technical roles.</li>
        <li><strong>Gauge performance in a practical setting - </strong> This comprehensive process ensures that only the top 2% of applicants are selected to join the BreezeLearn network.</li>
      </ol>`,
    },
    {
      question: "Can I request specific skills or expertise?",
      answer:
        "Absolutely! We work closely with you to understand your business needs and match you with professionals who have the exact skills, experience, and industry knowledge you require. Whether you need a software developer, AI engineer, sales expert, or a customer support representative, we have the right talent for you.",
    },
    {
      question: "What is the cost of hiring through BreezeLearn?",
      answer:
        "Our pricing is competitive and tailored to your specific needs. Factors influencing cost include role complexity, required experience level, and hiring duration (full-time vs. project-based). We offer transparent pricing with no hidden fees. On average, businesses save 20-30% compared to traditional hiring methods. Contact us for a customized quote, and we’ll provide a transparent breakdown of costs with no hidden fees.",
    },
  ];

  const handleNavigation = (link) => {
    history.push(link);
    // Ensure scroll to top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };
  return (
    <div style={{ background: "#F3F5FF" }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="faq-container"
        style={containerStyle}
      >
        <div className="faq-header">
          <h1>Frequently Asked Questions</h1>
          <p>Check out our frequently asked questions for quick answers:</p>
          <Button
            onClick={() => {
              handleNavigation("/contact_us");
            }}
          >
            Ask a question
          </Button>
        </div>

        <div className="faq-list">
          {faqData.map((faq, index) => (
            <motion.div key={index} initial={false} className="faq-item">
              <div
                className="faq-question"
                onClick={() => setOpenIndex(openIndex === index ? -1 : index)}
              >
                <span>{faq.question}</span>
                <div className="toggle-container">
                  {openIndex === index ? (
                    <X className="faq-icon" size={20} />
                  ) : (
                    <Plus className="faq-icon" size={20} />
                  )}
                </div>
              </div>

              <AnimatePresence initial={false}>
                {openIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="faq-answer"
                  >
                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default FAQSection;
