import React, { useEffect, useState } from "react";
import Navbar from "../../components/UI/navbar";
import ServicesSection from "./servicesSection";
import Hero from "./heroSection/hero";
import HireCard from "./hireSection/hire";
import FeaturedWork from "./featuredWork";
import Footer from "../../components/UI/footer";
import TestimonialsSection from "../../components/UI/Testimonials";
import FAQSection from "../../components/UI/faq";
import "swiper/css";
import HowItWorks from "./howitWorks/HowItWorks";
import { useLocation } from "react-router-dom";
import ProductSection from "./ProductSection/ProductSection";
import MobileHowItWorks from "./mobileHowItWorks/MobileHowItWorks";
// import BrandSection from "./brands";

const HomePage = () => {
  const [heroAnimationComplete, setHeroAnimationComplete] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Hero onAnimationComplete={() => setHeroAnimationComplete(true)} />
      <HireCard shouldStartAnimation={heroAnimationComplete} />
      <div
        style={{ marginBottom: "70px", width: "100%" }}
        className="ServicesSectionMargin"
      ></div>
      {/* <BrandSection /> */}
      <ServicesSection />
      <div
        style={{ marginBottom: "70px", width: "100%" }}
        className="ServicesSectionMargin"
      ></div>
      <HowItWorks />
      <MobileHowItWorks />
      <FeaturedWork />
      <div style={{ marginBottom: "50px", width: "100%" }} className=""></div>
      <ProductSection />
      <div
        style={{ marginBottom: "50px", width: "100%" }}
        className="ServicesSectionMargin"
      ></div>

      <TestimonialsSection />

      <FAQSection containerStyle={{ paddingTop: "0px" }} />
      <div
        style={{ marginTop: "240px", background: "#F3F5FF", width: "100%" }}
        className="ServicesSectionMargin"
      ></div>
    </>
  );
};

export default HomePage;
