import "./index.scss";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import img3 from "../../../assets/images/check-tick.svg";
import ServicesWithMarque from "./servicesWithMarque";
import { mainServices, services } from "./data";
import PhysisAnimation from "./physisAnimation";

gsap.registerPlugin(ScrollTrigger);

const ServicesSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1101);

  useEffect(() => {
    // Handle responsive state
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1101);
    };

    window.addEventListener("resize", handleResize);

    // GSAP animations
    let sections = gsap.utils.toArray(".main-service");
    sections.forEach((service, index) => {
      gsap.fromTo(
        service,
        { opacity: 1, y: index == 0 ? 0 : 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power1.out",
          scrollTrigger: {
            trigger: service,
            start: "top bottom",
            end: "top 30px",
            scrub: 0.1,
            toggleActions: "play reverse play reverse",
          },
        }
      );
    });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="services-main-container">
        <div className="services-wrapper">
          {/* Services sections will reorder based on CSS media queries */}
          <section className="main-services">
            <div className="main-services-container">
              {mainServices.map((service, index) => (
                <div key={index} className="main-service">
                  <div className="service-content">
                    <img
                      src={service.image}
                      alt={service.name}
                      className="service-icon"
                    />
                    <h2>{service.name}</h2>
                    <p>{service.description}</p>
                    <div className="tech-grid">
                      {service?.technologies.map((tech, techIndex) => (
                        <div key={techIndex} className="tech-item">
                          <img src={img3} alt="" />
                          <span>{tech.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Physics Animation Section */}
          <PhysisAnimation />
        </div>
      </div>
      <ServicesWithMarque />
    </>
  );
};

export default ServicesSection;
