import React, { useState, useEffect, useRef } from "react";
import { ArrowRightIcon } from "../../../assets/images/svg";
import { gsap } from "gsap";
import "./marque.scss";

const ServicesWithMarque = () => {
  const servicesMarquue = [
    { name: "Software Development" },
    { name: "Sales Development" },
    { name: "Website Design" },
    { name: "Customer Support" },
  ];

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 914);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const containerRef = useRef(null);
  const textRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 914);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Cleanup function for animations
  const cleanupAnimations = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (textRef.current) {
      gsap.killTweensOf(textRef.current.querySelectorAll(".char"));
    }
  };

  // Futuristic text animation effect
  useEffect(() => {
    if (isSmallScreen && textRef.current) {
      // Clean up any previous animations
      cleanupAnimations();

      // Get the current service text
      const text = servicesMarquue[currentServiceIndex].name;
      textRef.current.innerHTML = ""; // Clear previous content

      // Split text into words and characters
      const words = text.split(" ");

      words.forEach((word, wordIndex) => {
        // Create a word container
        const wordSpan = document.createElement("span");
        wordSpan.className = "word";

        // Add appropriate spacing between words
        if (wordIndex > 0) {
          const space = document.createElement("span");
          space.className = "space";
          space.innerHTML = "&nbsp;";
          textRef.current.appendChild(space);
        }

        // Split word into characters
        const chars = word.split("");
        chars.forEach((char) => {
          const span = document.createElement("span");
          span.textContent = char;
          span.className = "char";
          span.style.opacity = "0";
          span.style.transform =
            "translateY(20px) translateX(10px) rotateY(90deg)";
          wordSpan.appendChild(span);
        });

        textRef.current.appendChild(wordSpan);
      });

      // Animate characters in
      const charElements = textRef.current.querySelectorAll(".char");
      gsap.to(charElements, {
        opacity: 1,
        y: 0,
        x: 0,
        rotateY: 0,
        stagger: 0.05,
        ease: "power3.out",
        duration: 0.5,
        onComplete: () => {
          // Hold for a moment
          timeoutRef.current = setTimeout(() => {
            // Animate characters out
            gsap.to(charElements, {
              opacity: 0,
              y: -20,
              x: -10,
              rotateY: -90,
              stagger: 0.03,
              ease: "power2.in",
              duration: 0.4,
              onComplete: () => {
                // Move to next service
                setCurrentServiceIndex(
                  (prev) => (prev + 1) % servicesMarquue.length
                );
              },
            });
          }, 1500);
        },
      });
    }

    // Cleanup on unmount or when dependencies change
    return cleanupAnimations;
  }, [currentServiceIndex, isSmallScreen, servicesMarquue]);

  return (
    <div className="marqueServicesContainer" ref={containerRef}>
      {isSmallScreen ? (
        // Futuristic animated text for smaller screens
        <div className="futuristicTextContainer">
          <div className="textWrapper">
            <h2 ref={textRef} className="futuristicText"></h2>
            <div className="glowEffect"></div>
          </div>
        </div>
      ) : (
        // Static display for larger screens
        servicesMarquue.map((ser, index) => (
          <div className="marquesersWrap" key={index}>
            <h2>{ser.name}</h2>
            {index !== servicesMarquue.length - 1 && <ArrowRightIcon />}
          </div>
        ))
      )}
    </div>
  );
};

export default ServicesWithMarque;

// import React, { useState, useEffect, useRef } from "react";
// import { gsap } from "gsap";
// import "./marque.scss";

// const ServicesWithMarque = () => {
//   const servicesMarquue = [
//     { name: "Software Development" },
//     { name: "Sales Development" },
//     { name: "Website Design" },
//     { name: "Customer Support" },
//   ];

//   const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
//   const containerRef = useRef(null);
//   const textRef = useRef(null);
//   const timeoutRef = useRef(null);

//   // Cleanup function for animations
//   const cleanupAnimations = () => {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//     if (textRef.current) {
//       gsap.killTweensOf(textRef.current.querySelectorAll(".char"));
//     }
//   };

//   // Futuristic text animation effect
//   useEffect(() => {
//     if (textRef.current) {
//       // Clean up any previous animations
//       cleanupAnimations();

//       // Get the current service text
//       const text = servicesMarquue[currentServiceIndex].name;
//       textRef.current.innerHTML = ""; // Clear previous content

//       // Split text into words and characters
//       const words = text.split(" ");

//       words.forEach((word, wordIndex) => {
//         // Create a word container
//         const wordSpan = document.createElement("span");
//         wordSpan.className = "word";

//         // Add appropriate spacing between words
//         if (wordIndex > 0) {
//           const space = document.createElement("span");
//           space.className = "space";
//           space.innerHTML = "&nbsp;";
//           textRef.current.appendChild(space);
//         }

//         // Split word into characters
//         const chars = word.split("");
//         chars.forEach((char) => {
//           const span = document.createElement("span");
//           span.textContent = char;
//           span.className = "char";
//           span.style.opacity = "0";
//           span.style.transform =
//             "translateY(20px) translateX(10px) rotateY(90deg)";
//           wordSpan.appendChild(span);
//         });

//         textRef.current.appendChild(wordSpan);
//       });

//       // Animate characters in
//       const charElements = textRef.current.querySelectorAll(".char");
//       gsap.to(charElements, {
//         opacity: 1,
//         y: 0,
//         x: 0,
//         rotateY: 0,
//         stagger: 0.05,
//         ease: "power3.out",
//         duration: 0.5,
//         onComplete: () => {
//           // Hold for a moment
//           timeoutRef.current = setTimeout(() => {
//             // Animate characters out
//             gsap.to(charElements, {
//               opacity: 0,
//               y: -20,
//               x: -10,
//               rotateY: -90,
//               stagger: 0.03,
//               ease: "power2.in",
//               duration: 0.4,
//               onComplete: () => {
//                 // Move to next service
//                 setCurrentServiceIndex(
//                   (prev) => (prev + 1) % servicesMarquue.length
//                 );
//               },
//             });
//           }, 1500);
//         },
//       });
//     }

//     // Cleanup on unmount or when dependencies change
//     return cleanupAnimations;
//   }, [currentServiceIndex, servicesMarquue]);

//   return (
//     <div className="marqueServicesContainer" ref={containerRef}>
//       <div className="futuristicTextContainer">
//         <div className="textWrapper">
//           <h2 ref={textRef} className="futuristicText"></h2>
//           <div className="glowEffect"></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ServicesWithMarque;
