import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/UI/navbar";
import Footer from "./components/UI/footer";
import HomePage from "./pages/home/home";

const HireAFCTO = lazy(() => import("./pages/hireAFCTO/HireAFCTO"));
const AboutUs = lazy(() => import("./pages/aboutUs/AboutUs"));
const ContactUsPage = lazy(() => import("./pages/contactUs/contact"));
const Services = lazy(() => import("./pages/services/Services"));
const TrainingProgram = lazy(() =>
  import("./pages/trainingProgram/TrainingProgram")
);

const RouterBody = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/hire_FCTO" component={HireAFCTO} />
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/contact_us" component={ContactUsPage} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/training" component={TrainingProgram} />
      </Switch>
      <Footer />
    </Router>
  );
};

export default RouterBody;
