import React, { useState, useEffect } from "react";
import breezeLearnLogo from "../../../assets/images/breeze-learn-logo.png";
import "./index.css";
import Button from "../../button";
import { useLocation, useHistory } from "react-router-dom";
import Burger from "../mobileNav/Burger";
import { routes } from "./routes";

const Navbar = () => {
  const location = useLocation();
  const history = useHistory();

  const [isScrolled, setIsScrolled] = useState(false);
  const { pathname } = location;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Screen width resize listener
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);
    return () => window.removeEventListener("resize", changeWidth);
  }, []);

  // Scroll to top on path change
  useEffect(() => {
    try {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    } catch (error) {
      console.error("Scroll to top failed:", error);
    }
  }, [pathname]);

  // Navigation handler
  const handleNavigation = (link) => {
    history.push(link);
    // Ensure scroll to top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  return (
    <div>
      {screenWidth < 992 ? (
        <div className="mobilenavbar-optMenuFam">
          <div className="navbar-logo">
            <img src={breezeLearnLogo} alt="BreezeLearn Logo" />
          </div>
          <div>
            <Burger />
          </div>
        </div>
      ) : (
        <nav className={`navbar ${isScrolled ? "navbar-scrolled" : ""}`}>
          <div className="navbar-container">
            <div className="navbar-logo">
              <img
                src={breezeLearnLogo}
                alt="BreezeLearn Logo"
                onClick={() => {
                  handleNavigation(routes.home);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="navbar-links">
              <p
                className={`nav-link ${
                  pathname === routes.home ? "nav-link_active" : ""
                } `}
                onClick={() => {
                  handleNavigation(routes.home);
                }}
              >
                Home
              </p>
              <p
                className={`nav-link ${
                  pathname === routes.about ? "nav-link_active" : ""
                } `}
                onClick={() => {
                  handleNavigation(routes.about);
                }}
              >
                About Us
              </p>
              <p
                className={`nav-link ${
                  pathname === routes.services ? "nav-link_active" : ""
                } `}
                onClick={() => {
                  handleNavigation(routes.services);
                }}
              >
                Services
              </p>
              <p
                className={`nav-link ${
                  pathname === routes.training ? "nav-link_active" : ""
                } `}
                onClick={() => {
                  handleNavigation(routes.training);
                }}
              >
                Training Programs
              </p>

              <p
                className={`nav-link ${
                  pathname === routes.hireFTO ? "nav-link_active" : ""
                } `}
                onClick={() => {
                  handleNavigation(routes.hireFTO);
                }}
              >
                Hire a fCTO
              </p>
              <p
                className={`nav-link ${
                  pathname === routes.contact_us ? "nav-link_active" : ""
                } `}
                onClick={() => {
                  handleNavigation(routes.contact_us);
                }}
              >
                <Button activeColor="#0a0f2c " activeTextColor="#fff">
                  Contact Us
                </Button>
              </p>
            </div>
          </div>
        </nav>
      )}
    </div>
  );
};

export default Navbar;
